import { Component, OnInit } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import { version } from '../../../environments/version';
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styles: []
})
export class BreadcrumbsComponent implements OnInit {
  version: string = '';
  label: string = '';
  isfinantah: boolean;
  constructor(
    private router: Router,
    public title: Title,
    public meta: Meta
   ) {
    this.version = version.date.replace(/-/g, '') +': '+ version.hash;
    this.isfinantah = false;
    if (localStorage.getItem('quiensoy')) {
      if (localStorage.getItem('quiensoy') === 'finantah') {
        this.isfinantah = true;
      }
    }
    this.getDataRoute()
      .subscribe( data => {

      //  console.log( data );

        this.label = data.titulo;
        this.title.setTitle( this.label );

        let metaTag: MetaDefinition = {
          name: 'description',
          content: this.label
        };

        this.meta.updateTag(metaTag);

      });

  }

  getDataRoute() {

    return this.router.events
        .filter( evento => evento instanceof ActivationEnd  )
        .filter( (evento: ActivationEnd) => evento.snapshot.firstChild === null )
        .map( (evento: ActivationEnd) => evento.snapshot.data );

  }


  ngOnInit() {
  }

}
