import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';


@Injectable()
export class NotionService {
  // url de node
  //url = 'http://localhost:3000/api/v1';
  // url de rails
  //url = 'https://0cc60228-3838-4253-8832-36a32c234f5d-00-2brl7e6a7y3kn.picard.replit.dev/api/v1';
  url = environment.URL_SERVICIOS;
  constructor(
    public http: HttpClient,
    public router: Router
  ) { }

  obtenerIncidencias(params: any) {
    const url = `${this.url}/incidents` ;
    return this.http.get<any>(url, {params});
  }

  agregaComentario(incidentId: string, commentData: any) {
    
    const url = `${this.url}/incidents/${incidentId}/comments`;
    return this.http.post(url, commentData);
  }

  guardaIncidencia(data: any) {
    const url = `${this.url}/report-incident`;
    return this.http.post(url, data);

    }
  modifIncidencia(data: any) {
    const url = `${this.url}/update-incident/${data.id}`;
    return this.http.patch(url, data);
  }

  obtenerDetalleIncidencia(id: string) {
    const url = `${this.url}/incidents/${id}`
    return this.http.get<any>(url);
  }
}